import { PATH } from '@/data';
import { __ServiceCardSectionPropsType } from '../primaryServiceCard.type';
import styles from './desktopCard.module.scss';
import { TbArrowNarrowLeft, TbArrowNarrowRight } from 'react-icons/tb';
import GalleryComp from '../gallery/gallery.index';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import Link from 'next/link';
import Image from 'next/image';
import { FaStar } from 'react-icons/fa6';
import { LanguageEnum } from '@/types';
import { LuListTodo } from 'react-icons/lu';
import { IoChatbubbleEllipsesOutline, IoShareSocialOutline } from 'react-icons/io5';
import { Button } from 'antd';
export default function DeskServiceCardComp(props: __ServiceCardSectionPropsType) {
  const {
    langText,
    location
  } = useContext(GlobalContext);
  const lang = langText.components;
  const data = props.data;
  return <div className={`${styles['primary-card']} d-pre-lg-none`} data-sentry-component="DeskServiceCardComp" data-sentry-source-file="desktopCard.inde.tsx">
            <div className={`${styles['top-section']} d-flex gap-24 pb-2 mb-2`}>
                <GalleryComp link={PATH(langText.lang).services.detail(data)} images={data.gallery} data-sentry-element="GalleryComp" data-sentry-source-file="desktopCard.inde.tsx" />
                <div className={`${styles['details']}`}>
                    <div className={`h-fit justify-content-between ${styles['service-info']}`}>
                        <div className="h-fit gap-8">
                            {data.categories?.map(category => <Link key={category.id} target="_blank" href={PATH(langText.lang).services.category(location.slug, category.slug)} className="h-fit t-primary t-p2 t-500">
                                    {category.title}
                                </Link>)}
                            {data.locations?.length ? <span className="t-dark5">|</span> : null}
                            {data.locations?.map(loc => <p key={loc.id} className="h-fit t-primary t-p2 t-500">
                                    {loc.name}
                                </p>)}
                        </div>
                        <div className="h-fit center-content-y gap-8">
                            {data.avg_star && <p className="t-primary t-400 t-p1">
                                    <FaStar size={14} /> {data.avg_star?.toFixed(1)}
                                </p>}
                        </div>
                    </div>
                    <div className={`h-fit justify-content-between mb-2 pb-2 ${styles['service-title']}`}>
                        <a className="h-fit" target="_blank" href={PATH(langText.lang).services.detail(data)}>
                            <h4 className="t-dark1 t-h4 t-700">{data.title}</h4>
                        </a>
                        <p className="t-dark4 t-400 t-p2 h-fit">
                            {data.total_orders ?? '0'} {lang.sale}
                        </p>
                    </div>
                    <div className={`gap-16 h-fit ${styles['brand-title']}`}>
                        <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2 h-fit" data-sentry-element="Link" data-sentry-source-file="desktopCard.inde.tsx">
                            <Image src={data.brand.main_image ? data.brand.main_image.file : ''} width={40} height={40} alt={data.brand.main_image?.alt ?? ''} className="d-block" data-sentry-element="Image" data-sentry-source-file="desktopCard.inde.tsx" />
                        </Link>
                        <div className="flex-1 align-items-center justify-content-between">
                            <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2" data-sentry-element="Link" data-sentry-source-file="desktopCard.inde.tsx">
                                {data.brand.title}
                            </Link>
                            <Link href={PATH(langText.lang).brand(data.brand.slug)} data-sentry-element="Link" data-sentry-source-file="desktopCard.inde.tsx">
                                <p className="t-primary center-content gap-8 t-p2">
                                    {lang.see_more}
                                    {langText.lang === LanguageEnum.fa ? <TbArrowNarrowLeft size={18} /> : <TbArrowNarrowRight size={18} />}
                                </p>
                            </Link>
                        </div>
                    </div>
                    <div className="gap-16">
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.services(data.brand.services ?? 0)[0]}</p>
                            <p className="text-center">{lang.services(data.brand.services ?? 0)[1]}</p>
                        </div>
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.rank(0)[0]}</p>
                            <p className="text-center">{lang.rank(0)[1]}</p>
                        </div>
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.hiring(0)[0]}</p>
                            <p>{lang.hiring(0)[1]}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles['bottom-section']} justify-content-between`}>
                <div className={`${styles['info']} gap-16 justify-content-between`}>
                    <p onClick={() => props.setIsOpenModal('useTerms')} className={`${styles['terms']} pointer t-p1 t-400 t-primary align-items-center gap-8`}>
                        <LuListTodo data-sentry-element="LuListTodo" data-sentry-source-file="desktopCard.inde.tsx" /> {lang.terms_of_use}
                    </p>
                    <p onClick={() => props.setIsOpenModal('comments')} className={`${styles['comments']} pointer t-p1 t-400 t-primary align-items-center gap-8`}>
                        <IoChatbubbleEllipsesOutline data-sentry-element="IoChatbubbleEllipsesOutline" data-sentry-source-file="desktopCard.inde.tsx" /> {lang.comments}
                    </p>
                </div>
                <div className={`${styles['btn']} flex-1 gap-16`}>
                    <Button icon={<IoShareSocialOutline size={18} className="t-dark4" />} onClick={() => props.clipboardCopy(data.brand.slug, data.slug)} type="primary" data-sentry-element="Button" data-sentry-source-file="desktopCard.inde.tsx" />
                    <Button disabled={!!data.is_ordered} {...props.submitHandler} className="w-100" data-sentry-element="Button" data-sentry-source-file="desktopCard.inde.tsx">
                        {props.btnText}
                    </Button>
                </div>
            </div>
        </div>;
}