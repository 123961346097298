import { useContext, useState } from 'react';
import { GlobalContext } from '@/context';
import { HiringCategoryType, LanguageEnum } from '@/types';
import { RenderedCategory } from '@/components/serviceFilter/serviceFilter.type';
import { TbFilter, TbTrash } from 'react-icons/tb';
import { Button, Checkbox, Drawer, Form, Input, Radio, Select, Slider, Tree } from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { HiringData } from 'data';
import styles from './hiringFilter.module.scss';
import { __HiringFilterType } from '@/tehranto/hiringList/hiringList.type';
import { __HiringFilterPropsType } from './hiringFilter.type';
export default function HiringFilterComp(props: __HiringFilterPropsType) {
  const [search, setSearch] = useState<string>();
  const [showRange, setShowRange] = useState(props.filters.salaryLTE !== undefined || props.filters.salaryGTE !== undefined);
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.hiring;
  const initialSalaryRange: [number, number] = [0, 1000000];
  const involvedCategories: number[] = [];
  const filters = props.filters;
  const [form] = Form.useForm<__HiringFilterType>();
  const [salaryRange, setSalaryRange] = useState<[number, number]>([filters.salaryGTE ?? initialSalaryRange[0], filters.salaryLTE ?? initialSalaryRange[1]]);
  const onRemoveFilter = () => {
    props.setFilters({});
    form.resetFields();
    setSearch('');
    setSalaryRange(initialSalaryRange);
  };
  const renderSubCategory = (parent?: HiringCategoryType): RenderedCategory[] => {
    if (!props.categories?.filter(item => item.parent === parent?.id).length) null;
    return props.categories?.filter(i => i.parent?.id === parent?.id).map(item => {
      involvedCategories.push(item.id);
      return {
        title: <span className={`${langText.lang === LanguageEnum.fa ? item?.parent?.parent ? 'mr-7-i' : 'mr-3-i' : item?.parent?.parent ? 'ml-7-i' : 'ml-3-i'}`}>
                                {item.title}
                            </span>,
        key: item.id.toString(),
        children: renderSubCategory(item)
      };
    }) ?? [];
  };
  const firstLevelCategories = props.categories?.filter(i => !i.parent).map(item => {
    involvedCategories.push(item.id);
    return {
      title: <span>{item.title}</span>,
      key: item.id.toString(),
      children: renderSubCategory(item)
    };
  });
  const sliderHandler = (range: number[]) => {
    const newSalaryLTR = +range[1];
    const newSalaryGTE = +range[0];
    if (newSalaryLTR !== filters.salaryLTE || newSalaryGTE !== filters.salaryGTE) {
      setSalaryRange([newSalaryGTE, newSalaryLTR]);
      // props.setFilters({ ...filters, salaryLTE: newSalaryLTR, salaryGTE: newSalaryGTE, page: '1' });
      form.setFieldsValue({
        salaryGTE: newSalaryGTE,
        salaryLTE: newSalaryLTR,
        page: '1'
      });
    }
  };
  const categoryHandler = (selectedKeys: React.Key[]) => {
    form.setFieldsValue({
      category: selectedKeys.length ? selectedKeys[0].toString() : undefined,
      page: '1'
    });
    props.setFilters({
      ...filters,
      category: selectedKeys.length ? selectedKeys[0].toString() : undefined,
      page: '1'
    });
  };
  const onChangeAgreementSalary = (e: boolean) => {
    form.setFieldsValue({
      salaryGTE: undefined,
      salaryLTE: undefined
    });
    setShowRange(!e);
  };
  const filterComp = <>
            <div className="d-flex justify-content-between mb-3">
                <h4 className="t-700 t-h4 t-dark1 d-flex center-content gap-8">
                    <TbFilter size={18} /> {lang.filters}
                </h4>
                <p onClick={onRemoveFilter} className="t-400 t-p1 t-dark3 d-flex center-content gap-8 pointer">
                    <TbTrash size={18} /> {lang.delete}
                </p>
            </div>
            <Form layout="vertical" form={form} onFinish={e => props.setFilters({
      ...filters,
      ...e
    })} initialValues={props.filters}>
                <Form.Item<__HiringFilterType> name="search">
                    <Input.Search placeholder={lang.jobSearch} onSearch={e => props.setFilters({
          ...filters,
          search: e,
          page: '1'
        })} onChange={e => setSearch(e.target.value)} value={search ?? filters?.search ?? ''} />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="category">
                    <Tree treeData={firstLevelCategories?.concat(props.categories?.filter(i => !involvedCategories.includes(i.id)).map(item => ({
          title: <span>{item.title}</span>,
          key: item.id.toString(),
          children: renderSubCategory(item)
        })) ?? [])} switcherIcon={<IoIosArrowBack size={16} />} onSelect={categoryHandler} selectedKeys={[props.filters.category ?? '']} defaultExpandedKeys={[props.filters.category ?? '']} />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="workType" label={<p className="t-p2 t-500 t-dark2">{lang.jobType}</p>} valuePropName="checked" className={styles['form-item']}>
                    <Checkbox.Group options={HiringData.type(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                <Form.Item<__HiringFilterType> valuePropName="checked" name="time" label={<p className="t-p2 t-500 t-dark2">{lang.time}</p>} className={styles['form-item']}>
                    <Checkbox.Group options={HiringData.time(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="experience" label={<p className="t-p2 t-500 t-dark2">{lang.experience}</p>} valuePropName="checked" className={styles['form-item']}>
                    <Checkbox.Group options={HiringData.experience(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="gender" valuePropName="checked" label={<p className="t-p2 t-500 t-dark2">{lang.gender}</p>} className={styles['form-item']}>
                    <Checkbox.Group options={HiringData.gender(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="agreementSalary" label={<p className="t-p2 t-500 t-dark2">{lang.salary}</p>} className={styles['form-item']}>
                    <Radio.Group onChange={e => onChangeAgreementSalary(e.target.value)} options={HiringData.salary(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                {/* <Checkbox
                    className={styles['range-label']}
                    checked={showRange}
                    onChange={(e) => setShowRange(e.target.checked)}
                 >
                    {lang.range}
                 </Checkbox> */}
                <Form.Item<__HiringFilterType> name="salaryLTE" className="d-none-i">
                    <Input />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="salaryGTE" className="d-none-i">
                    <Input />
                </Form.Item>
                {showRange ? <Slider min={initialSalaryRange[0]} max={initialSalaryRange[1]} range value={salaryRange} onChange={sliderHandler} /> : null}
                <Form.Item<__HiringFilterType> name="salaryType" className={styles['form-item']} label={<p className="t-p2 t-500 t-dark2">{lang.salaryType}</p>} valuePropName="checked">
                    <Checkbox.Group options={HiringData.salary(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} className="flex-column gap-8" />
                </Form.Item>
                <Form.Item<__HiringFilterType> name="foreign" label={<p className="t-p2 t-500 t-dark2">{lang.foreign}</p>} className={styles['form-item']}>
                    <Select options={HiringData.foreign(lang).map(i => ({
          label: <p>{i.label}</p>,
          value: i.value
        }))} allowClear />
                </Form.Item>
                <Button className={`w-100 ${styles['submit-btn']}`} htmlType="submit">
                    {lang.applyFilter}
                </Button>
            </Form>
        </>;
  return <>
            <Drawer placement={langText.lang === LanguageEnum.en ? 'left' : 'right'} open={props.isOpen} onClose={() => props.setIsOpen(false)} width="100%" data-sentry-element="Drawer" data-sentry-source-file="hiringFilter.index.tsx">
                <div className="p-3">{filterComp}</div>
            </Drawer>
            <div className={`d-pre-lg-none flex-1 ${styles['hiring-filter']}`}>{filterComp}</div>
        </>;
}