'use client';

import { HiringType } from 'types';
import styles from './jobCard.module.scss';
import { useContext, useState } from 'react';
import { __HiringTabEnum } from './jpbCard.type';
import { GlobalContext } from 'context';
import { __HiringTabsData } from './jobCard.data';
import { TbArmchair, TbCoin, TbCreditCard, TbEdit } from 'react-icons/tb';
import { Divider } from 'antd';
import JobCardFooterComp from './footer/footer.index';
import { PATH, HiringData } from 'data';
export default function JobCardComp(props: {
  data: HiringType;
  onChange: (e: HiringType) => void;
}) {
  const job = props.data;
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.hiring;
  const [activeTab, setActiveTab] = useState(__HiringTabEnum.features);
  return <div className={`${styles['job-card']}`} data-sentry-component="JobCardComp" data-sentry-source-file="jobCard.index.tsx">
            <div className={`align-items-center ${styles['header']}`}>
                <a target="_blank" href={PATH(langText.lang).hiring.detail(props.data.slug)}>
                    <p className="t-700 t-dark1">{job.title}</p>
                </a>
            </div>
            <div className={`${styles['content']}`}>
                <div className={`gap-12 flex-wrap ${styles['tabs']}`}>
                    {__HiringTabsData(lang).map((item, index) => <div onClick={() => setActiveTab(item.slug)} key={index} className={`center-content pointer ${styles['item']} 
                                    ${item.slug === activeTab ? 't-500 t-dark2 ' + styles['active'] : 't-dark3 '}`}>
                            {item.title}
                        </div>)}
                </div>
                <div className={`flex-column mv-2 ${styles['features']}`}>
                    {activeTab === __HiringTabEnum.features ? <>
                            <div className={`flex-pre-md-column gap-12 ${styles['row']}`}>
                                <div className={`align-items-center ${styles['item']}`}>
                                    <TbEdit size={18} className="t-dark3" />
                                    <span className="t-dark3">{lang.time}:</span>
                                    <p>{HiringData.time(lang).find(i => i.value === job.contract)?.label}</p>
                                </div>
                                <Divider type="vertical" className="d-pre-md-none-i" />
                                <div className={`align-items-center ${styles['item']}`}>
                                    <TbArmchair size={18} className="t-dark3 d-md-none-i" />
                                    <span className="t-dark3">{lang.jobType}:</span>
                                    <p>{HiringData.type(lang).find(i => i.value === job.type)?.label}</p>
                                </div>
                            </div>
                            <div className={`flex-pre-md-column gap-12 ${styles['row']}`}>
                                <div className={`align-items-center ${styles['item']}`}>
                                    <TbCoin size={18} className="t-dark3" />
                                    <span className="t-dark3">{lang.salary}:</span>
                                    <p>{job.salary ? job.salary + ' CAD' : lang.agreement}</p>
                                </div>
                                <Divider type="vertical" className="d-pre-md-none-i" />
                                <div className={`align-items-center ${styles['item']}`}>
                                    <TbCreditCard size={18} className="t-dark3 d-md-none-i" />
                                    <span className="t-dark3">{lang.salaryType}:</span>
                                    <p>{HiringData.payment(lang).find(i => i.value === job.salary_type)?.label}</p>
                                </div>
                            </div>
                        </> : <p>
                            {activeTab === __HiringTabEnum.requirements ? job.requirements : activeTab === __HiringTabEnum.description ? job.description : activeTab === __HiringTabEnum.benefits ? job.benefits : activeTab === __HiringTabEnum.about ? job.about_company ?? job.brand.description : null}
                        </p>}
                </div>
                <JobCardFooterComp data={props.data} onChange={props.onChange} data-sentry-element="JobCardFooterComp" data-sentry-source-file="jobCard.index.tsx" />
            </div>
        </div>;
}