import { useContext } from 'react';
import { GlobalContext } from 'context';
import { Collapse, Drawer } from 'antd';
import { LanguageEnum, ServiceTypeEnum } from 'types';
import { usePathname } from 'next/navigation';
import styles from './drawer.module.scss';
import { TbWorld, TbTimelineEvent, TbUserCircle, TbBasketPlus, TbUsersGroup, TbChevronLeft, TbChevronRight, TbHomeSearch, TbHome,
// TbHeartHandshake,
TbBallpen } from 'react-icons/tb';
import Image from 'next/image';
import logo from 'assets/images/logo.svg';
import { AppLinkComp } from 'components';
import { PATH } from 'data';
import { IconType } from 'react-icons';
export default function DrawerComp(props: {
  open: boolean;
  onClose: () => void;
  hasSlug?: boolean;
}) {
  const {
    langText,
    profile,
    location
  } = useContext(GlobalContext);
  const lang = langText.header;
  const language = langText.lang;
  const pathname = usePathname();
  const isPage = (path?: string) => pathname.split('/').join('').split('?')[0] === path?.split('/').join('');
  const isHome = isPage(PATH(language).home());
  const isServicePage = isPage(PATH(language).services.index(location.slug));
  const isDating = isPage(PATH(language).dating);
  const isLink = (item?: ServiceTypeEnum) => !(isHome || isServicePage && item === ServiceTypeEnum.offer || isDating && item === ServiceTypeEnum.dating);
  const nextLang = () => {
    const nextL = language === LanguageEnum.fa ? LanguageEnum.en : LanguageEnum.fa;
    return {
      fontFamily: nextL === LanguageEnum.fa ? 'AnjomanMax' : 'AnjomanMax',
      link: `\\${nextL}` + (props.hasSlug ? '' : pathname.slice(3))
    };
  };
  const items = [{
    title: lang.home,
    icon: TbHome,
    href: PATH(language).home()
  }, {
    title: lang.services,
    icon: TbBasketPlus,
    href: PATH(language).services.index(location.slug)
  },
  // {
  //     title: lang.dating,
  //     icon: TbHeartHandshake,
  //     href: profile ? PATH(language).dating : undefined,
  //     search: profile ? undefined : ServiceTypeEnum.dating,
  // },
  {
    title: lang.housing,
    icon: TbHomeSearch,
    children: [{
      title: lang.houses,
      href: PATH(language).houses.index(location.slug)
    }, {
      title: lang.tenants,
      href: PATH(language).roommates.index(location.slug)
    }]
  }, {
    title: lang.events,
    icon: TbTimelineEvent,
    search: ServiceTypeEnum.events
  }, {
    title: lang.blog,
    icon: TbBallpen,
    href: PATH(language).blog.index()
  }, {
    title: lang.aboutUs,
    icon: TbUsersGroup,
    href: PATH(language).aboutUs
  }, {
    title: profile ? profile.first_name ? profile.first_name : lang.profile : lang.login,
    icon: TbUserCircle,
    href: profile ? PATH(language).panel.index : PATH(language).login()
  }];
  const Arrow = language === LanguageEnum.en ? TbChevronRight : TbChevronLeft;
  const link = (item: {
    title: string;
    href?: string;
    search?: ServiceTypeEnum;
    icon?: IconType;
  }, index?: number, nested?: boolean) => <AppLinkComp href={item.href ?? PATH(language).home(item.search)} key={index} onClick={props.onClose} searchParamsSensitive={false} preventReload={item.search ? !isLink(item.search) : isPage(item.href)} className={`t-dark1 justify-content-between t-h4 pv-${nested ? '1' : '2'} ${isPage(item.href) ? 't-primary-i t-500' : ''}`} data-sentry-element="AppLinkComp" data-sentry-component="link" data-sentry-source-file="drawer.index.tsx">
            <div className="align-items-center gap-8 ">
                {item.icon ? <item.icon size={isPage(item.href) ? 24 : 20} /> : null}
                <span>{item.title}</span>
            </div>
            <Arrow size={20} data-sentry-element="Arrow" data-sentry-source-file="drawer.index.tsx" />
        </AppLinkComp>;
  return <Drawer open={props.open} onClose={props.onClose} className={styles['header-drawer']} placement={language === LanguageEnum.en ? 'left' : 'right'} title={<h1 className="t-h1 t-primary align-items-center gap-8">
                    <Image src={logo} alt="Tehranto" width={36} height={36} />
                    {lang.tehranto}
                </h1>} width={'100%'} data-sentry-element="Drawer" data-sentry-component="DrawerComp" data-sentry-source-file="drawer.index.tsx">
            <div className="flex-column h-100 p-3 pt-1-i">
                {items.map((item, index) => item.children ? <Collapse key={index} className={`collapse-info ${styles['collapse']}`} expandIconPosition="end" items={[{
        key: index.toString(),
        label: <div className="align-items-center gap-8 t-h4 t-dark1 t-400">
                                            {item.icon ? <item.icon size={isPage(item.href) ? 24 : 20} /> : null}
                                            <span>{item.title}</span>
                                        </div>,
        children: <div className={'ph-2 flex-column'}>
                                            {item.children.map((child, index) => link(child, index, true))}
                                        </div>
      }]} /> : link(item, index))}
                <a aria-label="change language" href={nextLang().link} className={`gap-8 t-dark1 justify-content-between t-h4 pv-2`}>
                    <div className="align-items-center gap-8 ">
                        <TbWorld size={20} data-sentry-element="TbWorld" data-sentry-source-file="drawer.index.tsx" />
                        <span style={{
            fontFamily: nextLang().fontFamily
          }}>{lang.otherLang}</span>
                    </div>
                    <Arrow size={20} data-sentry-element="Arrow" data-sentry-source-file="drawer.index.tsx" />
                </a>
            </div>
        </Drawer>;
}