'use client';

import { Button, Divider, Drawer, Form, Input, message, Modal, Select, Space } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'context';
import { AppStorage, RestAPI, ToEnglish, useAsyncClick, useBP } from '@/scripts';
import { API, PATH } from 'data';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { __PhoneData } from 'tehranto/signupOTP/firstStep/firstStep.data';
import { AppContextActionKeyEnum, JWTTokenType, ProfileType } from '@/types';
import styles from './authModal.module.scss';
function __AuthModalComp(props: {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}) {
  const {
    langText,
    update
  } = useContext(GlobalContext);
  const lang = langText.signupOTP;
  const [phoneForm] = Form.useForm();
  const [codeForm] = Form.useForm();
  const [step, setStep] = useState<'phone' | 'code'>('phone');
  const [messageApi, contextHolder] = message.useMessage();
  const [countdown, setCountdown] = useState<number>(0);
  const timer = useRef<NodeJS.Timeout>();
  const [search, setSearch] = useState('');
  const bp = useBP();
  const isMobile = bp?.MD_LTE;
  polyfillCountryFlagEmojis();
  const reSend = () => {
    if (countdown === 0) phoneForm.submit();
  };
  const editPhone = () => {
    phoneForm.setFieldValue('phone', null);
    setStep('phone');
  };
  const onChangeForm = () => {
    if (step === 'code') {
      const values = codeForm.getFieldsValue();
      codeForm.setFields(Object.keys(values).map(i => ({
        name: i,
        errors: undefined
      })));
    } else {
      const values = phoneForm.getFieldsValue();
      phoneForm.setFields(Object.keys(values).map(i => ({
        name: i,
        errors: undefined
      })));
    }
  };
  const phoneSubmitHandler = (values: {
    phone: string;
    prefix: string;
  }) => {
    return new Promise(resolve => {
      AppStorage.authToken.remove();
      const phone = `${values.prefix.split('|')[0]}${ToEnglish(values.phone)}`;
      RestAPI.post(API.register.register, {
        phone
      }).then(() => {
        setStep('code');
        setCountdown(120);
      }).catch(() => messageApi.open({
        type: 'error',
        content: lang.error
      })).then(() => resolve(true));
    });
  };
  const phoneHandler = useAsyncClick(phoneSubmitHandler);
  const submitHandler = (value: any) => {
    return new Promise(resolve => {
      const phoneValue = phoneForm.getFieldsValue();
      const phone = `${phoneValue.prefix.split('|')[0]}${ToEnglish(phoneValue.phone)}`;
      RestAPI.post<JWTTokenType>(API.middlewares.login, {
        phone,
        code: ToEnglish(value.code)
      }).then(res => {
        AppStorage.authToken.set(res.data);
        RestAPI.get<ProfileType>(API.register.me).then(res => {
          update({
            key: AppContextActionKeyEnum.profile,
            value: res.data
          });
          props.onSuccess?.();
          resolve(true);
        }).catch(() => {
          messageApi.open({
            type: 'error',
            content: langText.general.error
          });
          resolve(true);
        });
      }).catch(() => {
        messageApi.open({
          type: 'error',
          content: lang.codeSubmitError
        });
        resolve(true);
      });
    });
  };
  const codeHandler = useAsyncClick(submitHandler);
  useEffect(() => {
    if (countdown > 0) timer.current = setInterval(() => setCountdown(prevCountdown => prevCountdown - 1), 1000);else if (timer.current) {
      clearInterval(timer.current);
      timer.current = undefined;
    }
    return () => {
      if (timer) clearInterval(timer.current);
    };
  }, [countdown]);
  const terms = lang.terms.split('--');
  const Content = <>
            <div className={`${styles['form-layout']}`}>
                <h2 className="t-900 t-p2 flex-1 w-100">{props.title ?? lang.login_title}</h2>
                <p className="mt-2 t-p2 t-400 mb-3">
                    {step === 'phone' ? lang.login_desc : <>
                            {`${lang.codeDesc1} ${phoneForm.getFieldValue('phone')} ${lang.codeDesc2}`}
                            <span className="t-primary ph-1-i t-p2 pointer" onClick={editPhone}>
                                {lang.change_phone}
                            </span>
                        </>}
                </p>
                <Form layout="vertical" form={phoneForm} onFinish={phoneHandler.onClick} onChange={onChangeForm} className={step === 'phone' ? '' : 'd-none-i'}>
                    <Form.Item name="phone" label={lang.phone} validateTrigger={false} rules={[{
          required: true,
          message: lang.phoneRequired
        }]}>
                        <Input addonBefore={<Form.Item name="prefix" noStyle rules={[{
            required: true
          }]} initialValue={__PhoneData.find(i => i.desc === 'Canada')?.value}>
                                    <Select popupMatchSelectWidth={false} optionLabelProp="newLabel" options={__PhoneData.filter(i => i.value === 'divider' ? 1 : search.length ? ToEnglish(i.label).includes(search) || i.desc.toLowerCase().includes(search.toLowerCase()) : 1)} dropdownRender={menu => <>
                                                <Space.Compact className="p-1" style={{
                maxWidth: 275
              }}>
                                                    <Input onChange={s => setSearch(s.target.value)} allowClear placeholder={lang.search} />
                                                </Space.Compact>
                                                <Divider style={{
                margin: '8px 0'
              }} />
                                                {menu}
                                            </>} optionRender={option => option.data.short !== 'divider' ? <Space>
                                                    <span role="img" style={{
                fontFamily: 'Twemoji Country Flags'
              }} aria-label={option.data.label}>
                                                        {option.data.emoji}
                                                    </span>
                                                    {option.data.desc.substring(0, 25) + (option.data.desc.length > 25 ? '...' : '')}
                                                </Space> : <Divider className="m-0-i" />} />
                                </Form.Item>} className={`${styles['input-phone']} w-100`} />
                    </Form.Item>
                    <p className=" t-p2">
                        {terms[0]}
                        <a href={PATH(langText.lang).terms} className="t-p2" target="_blank">
                            {terms[1]}
                        </a>
                        {terms[2]}
                    </p>
                    <div className={styles['actions']}>
                        <Button type="text" onClick={() => props.onClose?.()}>
                            {langText.general.cancel}
                        </Button>
                        <Form.Item className="mb-0-i">
                            <Button loading={phoneHandler.loading} htmlType="submit" className={`w-100`}>
                                {lang.sendPhone}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <Form layout="vertical" form={codeForm} onFinish={codeHandler.onClick} onChange={onChangeForm} className={step === 'code' ? '' : 'd-none-i'}>
                    <Form.Item className={`mb-2-i ${styles['ltr-input']}`} name="code" rules={[{
          required: true,
          message: lang.codeRequired
        }]} label={<p className="t-500 t-p2">{lang.code}</p>} validateTrigger={false}>
                        <Input.OTP size="large" length={5} variant="filled" />
                    </Form.Item>
                    <p>
                        {lang.resend_code_first}{' '}
                        <span className={`${countdown === 0 ? 't-primary pointer' : ''}`} onClick={reSend}>
                            {lang.resend_code_second}
                        </span>{' '}
                        <span className={`${countdown === 0 ? 'd-none' : ''}`}>
                            {lang.resend_code_third} {countdown} {lang.resend_code_end}
                        </span>
                    </p>
                    <div className={styles['actions']}>
                        <Button type="text" onClick={() => props.onClose?.()}>
                            {langText.general.cancel}
                        </Button>
                        <Form.Item className="m-0-i">
                            <Button loading={codeHandler.loading} htmlType="submit" className={`w-100`}>
                                {lang.login_account}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </>;
  return <>
            {contextHolder}
            {isMobile ? <Drawer open={props.open} placement="bottom" height={'auto'} className="pt-4 pb-2 ph-2" closeIcon={null}>
                    {Content}
                </Drawer> : <Modal open={props.open} closeIcon={null} footer={null}>
                    {Content}
                </Modal>}
        </>;
}
export default __AuthModalComp;