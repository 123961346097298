'use client';

import { GlobalContext } from '@/context';
import { API } from '@/data';
import { RestAPI } from '@/scripts';
import { LocationType, PaginationType } from '@/types';
import { message, Result, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { TbInfoCircleFilled } from 'react-icons/tb';
export default function LocationSelectComp(props: {
  placeholder?: string;
  value?: LocationType<'detail'> | string; // antd fom item props, use `initialValue`
  initialValue?: LocationType<'detail'> | undefined;
  onChange?: (e?: LocationType<'detail'>) => void; // antd fom item props, use `onLocationChange`
  onLocationChange?: (e?: LocationType<'detail'>) => void;
}) {
  const [locationList, setLocationList] = useState<LocationType<'detail'>[]>();
  const {
    langText,
    location
  } = useContext(GlobalContext);
  const lang = langText.components;
  const title = (e: LocationType<'detail'>) => e?.parent ? `${e?.parent?.name} - ${e?.name}` : `${e?.name}`;
  const initialValue = props.initialValue ?? props.value;
  const [selected, setSelected] = useState(initialValue ? typeof initialValue === 'string' ? {
    value: initialValue,
    label: initialValue
  } : {
    value: initialValue.slug,
    label: title(initialValue)
  } : undefined);

  // search location
  const searchHandler = (value: string) => {
    RestAPI.get<PaginationType<LocationType<'detail'>>>(API.location.index, {
      search: value,
      page_size: 40,
      is_active: true
    }).then(res => {
      if (props.initialValue) setLocationList(res.data.data.filter(i => i.slug !== props.initialValue?.slug).concat(props.initialValue));else setLocationList(res.data.data.filter(i => i.slug !== location.slug).concat(location));
    }).catch(() => message.error(lang.locationSearchErr));
  };
  useEffect(() => {
    searchHandler('');
  }, []);
  useEffect(() => {
    if (locationList) {
      if (props.onChange) props.onChange(locationList?.find(i => i.slug === selected?.value));
      if (props.onLocationChange) props.onLocationChange(locationList?.find(i => i.slug === selected?.value));
    }
  }, [selected, locationList]);
  return <Select showSearch className="w-100" placeholder={props.placeholder} optionFilterProp="label"
  // allowClear
  filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} value={selected} onSearch={e => searchHandler(e)} options={props.value && !locationList?.length ? typeof props.value === 'string' ? [{
    value: props.value,
    label: props.value
  }] : [props.value].map(i => ({
    value: i.slug,
    label: title(i)
  })) : locationList?.map(i => ({
    value: i.slug,
    label: title(i)
  }))} onChange={(values, options) => {
    if (!Array.isArray(options) && options && options.value) setSelected({
      value: options.value,
      label: options.label
    });else setSelected(undefined);
  }} dropdownRender={menu => <>
                    {locationList === undefined ? <Result icon={<TbInfoCircleFilled size={36} className="t-primary" />} status="info" title={lang.startType} subTitle={lang.startTypeDesc} /> : menu}
                </>} data-sentry-element="Select" data-sentry-component="LocationSelectComp" data-sentry-source-file="locationSelect.index.tsx" />;
}