'use client';

import { CacheBackTypeEnum, OrderType } from 'types';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from 'context';
import DetailServiceModalComp from './detailModal/modal.index';
import { API, PATH } from 'data/index';
import { CopyToClipboard, RestAPI, useAsyncClick, useEventListener } from 'scripts';
import { __PrimaryServiceCardPropsType } from './primaryServiceCard.type';
import { SuccessOfferBuyModalComp } from 'components';
import DeskServiceCardComp from './desktopCard/desktopCard.inde';
import MobServiceCardComp from './mobileCard/mobileCard.index';
import { message } from 'antd';
export default function PrimaryServiceCardComp({
  data,
  onBuy,
  params
}: __PrimaryServiceCardPropsType) {
  const [isOpenModal, setIsOpenModal] = useState<'useTerms' | 'comments'>();
  const {
    langText,
    profile,
    location
  } = useContext(GlobalContext);
  const [orderData, setOrderData] = useState<OrderType>();
  const [printable, setPrintable] = useState(false);
  const timer = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const dataSent = useRef(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const lang = langText.components;
  const options: string[] = [];
  if (data.cash_back) options.push(`${data.cash_back} ${data.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%'} ${data.cash_back_label?.length ? data.cash_back_label : lang.refund}`);
  data.extra_service?.forEach(item => {
    options.push(item);
  });
  const btnText = data.is_ordered ? lang.selected : lang.choose_to_buy + ' ' + (options.length ? options.join(' + ') : '');
  const print = () => {
    if (dataSent.current) {
      setLoading(false);
      clearInterval(timer.current);
      timer.current = undefined;
      iframeRef.current?.contentWindow?.focus();
      iframeRef.current?.contentWindow?.print();
    } else setLoading(true);
  };
  const handleCopyToClipboard = () => {
    CopyToClipboard(`${window.location.host}${PATH(langText.lang).services.detail(data)}`);
    message.success(lang.copy_alert);
  };
  const submitHandlerHandler = useAsyncClick(() => {
    return new Promise(resolve => {
      if (!profile) {
        window.location.href = PATH(langText.lang).login(PATH(langText.lang).services.index(location.slug, params));
        resolve(true);
      } else if (data.is_ordered !== 1) {
        RestAPI.post<OrderType>(API.order.init, {
          service: data.id
        }).then(res => {
          onBuy();
          setOrderData(res.data);
        }).catch(() => message.error(lang.initializeError)).finally(() => resolve(true));
      } else resolve(true);
    });
  });
  useEffect(() => {
    if (printable && !dataSent.current && orderData) {
      iframeRef.current?.contentWindow?.postMessage(JSON.stringify({
        service: orderData?.service,
        order: orderData
      }), '*');
      dataSent.current = true;
    }
  }, [printable, data, orderData]);
  useEffect(() => {
    if (loading) timer.current = setInterval(print, 500);
  }, [loading]);
  useEventListener('message', event => {
    if (event.data === 'PRINT_IFRAME_LOADED') setPrintable(true);
  });
  return <>
            <SuccessOfferBuyModalComp data={data} order={orderData} open={!!orderData} onClose={() => setOrderData(undefined)} printable={printable} onPrint={print} printLoading={loading || !printable} data-sentry-element="SuccessOfferBuyModalComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
            {orderData ? <iframe src={PATH(langText.lang).orderPrint} style={{
      width: '100%',
      height: 0,
      opacity: 0,
      border: 0
    }} ref={iframeRef} /> : null}
            <DeskServiceCardComp data={data} clipboardCopy={handleCopyToClipboard} submitHandler={submitHandlerHandler} btnText={btnText} setIsOpenModal={setIsOpenModal} data-sentry-element="DeskServiceCardComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
            <MobServiceCardComp data={data} clipboardCopy={handleCopyToClipboard} submitHandler={submitHandlerHandler} btnText={btnText} setIsOpenModal={setIsOpenModal} data-sentry-element="MobServiceCardComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
            <DetailServiceModalComp data={data} title={data.title} setIsOpenModal={setIsOpenModal} type={isOpenModal} data-sentry-element="DetailServiceModalComp" data-sentry-source-file="primaryServiceCard.index.tsx" />
        </>;
}