'use client';

import { HouseType, RentEnum } from '@/types';
import styles from './housesCard.module.scss';
import Image from 'next/image';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import { PATH } from '@/data';
import Link from 'next/link';
import { RentTypeData, GenderData, HouseTitle, AccommodationTypeData } from 'data';
import { ExtractTitle, HousingPriceConvert } from '@/scripts';
import { TbCamera } from 'react-icons/tb';
function HousesCardComp({
  item,
  className
}: {
  item: HouseType;
  className?: string;
}) {
  const langText = useContext(GlobalContext).langText;
  const lang = langText.housesList;
  const language = langText.lang;
  const mainImage = item.attachments?.length ? item.attachments[0] : undefined;
  const price = HousingPriceConvert(item.price, item.price_mode);
  return <Link href={PATH(language).houses.detail(item)} target="_blank" className={`${className ?? ''}`} data-sentry-element="Link" data-sentry-component="HousesCardComp" data-sentry-source-file="housesCard.index.tsx">
            <div className={`${styles['houses-card-layout']} p-2`}>
                <div className={`${styles['image']} mb-2`}>
                    <span className={`${item.rent_type == RentEnum.Individual ? 'd-none' : ''}`}>
                        {lang.gender1} {ExtractTitle(GenderData(lang, true), item.owner_gender)} {lang.gender2}{' '}
                        {ExtractTitle(GenderData(lang), item.desired_gender)} {lang.gender3}
                    </span>
                    {mainImage ? <Image width={328} height={200} src={mainImage.file} alt={mainImage.alt ?? HouseTitle(item, lang) ?? ''} /> : <div className={`w-100 h-100 center-content ${styles['empty-state']}`}>
                            <TbCamera className="t-dark3" size={48} />
                        </div>}
                </div>
                <p className="t-p1 t-700 t-dark1 mb-1">{HouseTitle(item, lang)}</p>
                <p className="t-p1 t-400 t-primary">{item.location.name}</p>
                <p className="t-p1 t-400 t-dark2">
                    {lang.residenceType}: {ExtractTitle(AccommodationTypeData, item.accommodation_type)}
                </p>
                <span className="d-flex align-items-center justify-content-between">
                    <p className={`t-p1 t-400 t-${item.rent_type == RentEnum.Communal ? 'red' : 'green'}`}>
                        {ExtractTitle(RentTypeData(lang), item.rent_type)}
                    </p>
                    <p className="t-dark4 t-p1 t-400">
                        {price.modeText(lang) + ' '}
                        <span className="t-p1 t-700 t-dark1">{price.show} CAD</span>
                    </p>
                </span>
            </div>
        </Link>;
}
export default HousesCardComp;