import { useContext } from 'react';
import { __ServiceCardSectionPropsType } from '../primaryServiceCard.type';
import styles from './mobileCard.module.scss';
import { GlobalContext } from '@/context';
import GalleryComp from '../gallery/gallery.index';
import { PATH } from '@/data';
import Link from 'next/link';
import { IoChatbubbleEllipsesOutline, IoShareSocialOutline } from 'react-icons/io5';
import { FaStar } from 'react-icons/fa6';
import Image from 'next/image';
import { LuListTodo } from 'react-icons/lu';
import { Button } from 'antd';
import { LanguageEnum } from '@/types';
import { TbArrowNarrowLeft, TbArrowNarrowRight } from 'react-icons/tb';
export default function MobServiceCardComp(props: __ServiceCardSectionPropsType) {
  const {
    langText,
    location
  } = useContext(GlobalContext);
  const lang = langText.components;
  const data = props.data;
  return <div className={`${styles['primary-card-lg']} d-none d-pre-lg-block pb-3`} data-sentry-component="MobServiceCardComp" data-sentry-source-file="mobileCard.index.tsx">
            <GalleryComp link={PATH(langText.lang).services.detail(data)} images={data.gallery} data-sentry-element="GalleryComp" data-sentry-source-file="mobileCard.index.tsx" />
            <div className="ph-4 mt-3">
                <div className={`${styles['top-section-lg']} pb-2 mb-2`}>
                    <div className="d-flex justify-content-between mb-1">
                        <div className="gap-8">
                            {data.categories?.map(category => <Link key={category.id} target="_blank" href={PATH(langText.lang).services.category(location.slug, category.slug)} className="t-primary t-p2 t-500">
                                    {category.title}
                                </Link>)}
                            {data.locations?.length ? <span className="t-dark5">|</span> : null}
                            {data.locations?.map(loc => <p key={loc.id} className="t-primary t-p2 t-500">
                                    {loc.name}
                                </p>)}
                        </div>
                        <Button icon={<IoShareSocialOutline size={18} className="t-dark4" />} onClick={() => props.clipboardCopy(data.brand.slug, data.slug)} type="primary" size="small" data-sentry-element="Button" data-sentry-source-file="mobileCard.index.tsx" />
                    </div>
                    <a target="_blank" href={PATH(langText.lang).services.detail(data)}>
                        <h4 className="t-dark1 t-h4 t-700 mb-1">{data.title}</h4>
                    </a>
                    <div className={`${styles['border-bottom']} pb-2 mb-2 align-items-center gap-8 `}>
                        <p className={`t-p1 t-400 t-primary ${typeof data.avg_star === 'number' ? '' : 'd-none'}`}>
                            {[1, 2, 3, 4, 5].map(star => <FaStar key={star} size={12} className={data.avg_star?.toFixed(1) !== undefined ? star <= +data.avg_star?.toFixed(1) ? 't-primary' : 't-dark5' : 't-dark5'} />)}{' '}
                            {data.avg_star?.toFixed(1)}
                        </p>
                        <p className={`t-dark4 t-400 t-p2 flex-1`}>
                            {data.total_orders ?? 0} {lang.sale}
                        </p>
                        <Link href={PATH(langText.lang).services.detail(data)} data-sentry-element="Link" data-sentry-source-file="mobileCard.index.tsx">
                            <p className="t-primary center-content gap-8 t-p2">
                                {lang.serviceDetail}
                                {langText.lang === LanguageEnum.fa ? <TbArrowNarrowLeft size={18} /> : <TbArrowNarrowRight size={18} />}
                            </p>
                        </Link>
                    </div>
                    <div className="align-items-center justify-content-between mb-2">
                        <div className="align-items-center gap-16">
                            <Link href={PATH(langText.lang).brand(data.brand.slug)} target="_blank" data-sentry-element="Link" data-sentry-source-file="mobileCard.index.tsx">
                                <Image src={data.brand.main_image?.file ?? ''} width={50} height={50} alt={data.brand.main_image?.alt ?? ''} className={styles['brand-cover']} data-sentry-element="Image" data-sentry-source-file="mobileCard.index.tsx" />
                            </Link>
                            <Link target="_blank" href={PATH(langText.lang).brand(data.brand.slug)} className="t-p1 t-500 t-dark2" data-sentry-element="Link" data-sentry-source-file="mobileCard.index.tsx">
                                {data.brand.title}
                            </Link>
                        </div>
                        <Link href={PATH(langText.lang).brand(data.brand.slug)} data-sentry-element="Link" data-sentry-source-file="mobileCard.index.tsx">
                            <p className="t-primary center-content gap-8 t-p2">
                                {lang.brandDetail}
                                {langText.lang === LanguageEnum.fa ? <TbArrowNarrowLeft size={18} /> : <TbArrowNarrowRight size={18} />}
                            </p>
                        </Link>
                    </div>
                    <div className="flex-column gap-8">
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.services(data.brand.services ?? 0)[0]}</p>
                            <p className="text-center">{lang.services(data.brand.services ?? 0)[1]}</p>
                        </div>
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.rank(0)[0]}</p>
                            <p className="text-center">{lang.rank(0)[1]}</p>
                        </div>
                        <div className={`${styles['brand-info']}`}>
                            <p>{lang.hiring(0)[0]}</p>
                            <p>{lang.hiring(0)[1]}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles['bottom-section-lg']}`}>
                    <div className="d-flex justify-content-between mb-2">
                        <p onClick={() => props.setIsOpenModal('useTerms')} className={`${styles['terms-lg']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                            <LuListTodo data-sentry-element="LuListTodo" data-sentry-source-file="mobileCard.index.tsx" /> {lang.terms_of_use}
                        </p>
                        <p onClick={() => props.setIsOpenModal('comments')} className={`${styles['comments-lg']} t-p1 t-400 t-primary d-flex align-items-center gap-8`}>
                            <IoChatbubbleEllipsesOutline data-sentry-element="IoChatbubbleEllipsesOutline" data-sentry-source-file="mobileCard.index.tsx" /> {lang.customers_comments}
                        </p>
                    </div>
                    <Button className="w-100" disabled={!!data.is_ordered} {...props.submitHandler} data-sentry-element="Button" data-sentry-source-file="mobileCard.index.tsx">
                        <p>{props.btnText}</p>
                    </Button>
                </div>
            </div>
        </div>;
}