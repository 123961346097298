import { AppLinkComp } from '@/components';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useAsyncClick } from '@/scripts';
import { HiringType } from '@/types';
import { Button, message } from 'antd';
import Image from 'next/image';
import { useContext } from 'react';
import { TbMapPinFilled, TbStar, TbStarFilled } from 'react-icons/tb';
import styles from './footer.module.scss';
export default function JobCardFooterComp(props: {
  data: HiringType;
  onChange: (e: HiringType) => void;
}) {
  const job = props.data;
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const lang = langText.hiring;
  const img = job.brand.main_image ?? (job.brand.gallery?.length ? job.brand.gallery?.[0] : undefined);
  const link = (child: React.ReactNode) => <AppLinkComp href={PATH(langText.lang).login(PATH(langText.lang).hiring.detail(props.data.slug))} data-sentry-element="AppLinkComp" data-sentry-component="link" data-sentry-source-file="footer.index.tsx">
            {child}
        </AppLinkComp>;
  const requestClick = useAsyncClick(() => {
    return new Promise(resolve => {
      const req = props.data.request_id ? RestAPI.delete : RestAPI.post;
      const url = props.data.request_id ? API.hiring.request.detail(props.data.request_id) : API.hiring.request.index;
      req<{
        id: number;
      }>(url, props.data.request_id ? {
        hiring: [props.data.id]
      } : {}).then(res => {
        resolve(true);
        props.onChange({
          ...props.data,
          request_id: props.data.request_id ? undefined : res.data?.id
        });
      }).catch(() => {
        resolve(false);
        message.error(props.data.request_id ? lang.cancelRequestErr : lang.requestErr);
      });
    });
  });
  const bookmarkClick = useAsyncClick(() => {
    return new Promise(resolve => {
      const req = props.data.bookmark_id ? RestAPI.delete : RestAPI.post;
      const url = props.data.bookmark_id ? API.hiring.bookmark.detail(props.data.bookmark_id) : API.hiring.bookmark.index;
      req<{
        id: number;
      }>(url, props.data.bookmark_id ? {
        hiring: [props.data.id]
      } : {}).then(res => {
        resolve(true);
        props.onChange({
          ...props.data,
          bookmark_id: props.data.bookmark_id ? undefined : res.data?.id
        });
      }).catch(() => {
        resolve(false);
        message.error(props.data.bookmark_id ? lang.bookmarkRemoveErr : lang.bookmarkErr);
      });
    });
  });
  return <div className={`justify-content-between align-items-center ${styles['tools']}`} data-sentry-component="JobCardFooterComp" data-sentry-source-file="footer.index.tsx">
            <div className={`align-items-center gap-12 ${styles['brand']}`}>
                {img ? <Image src={img.file} alt={job.brand.title} width={50} height={50} /> : null}
                <div>
                    <p className="t-500">{job.brand.title}</p>
                    <div className={`gap-8 align-items-center ${job.location ? '' : 'd-none'}`}>
                        <TbMapPinFilled className="t-dark4" size={18} data-sentry-element="TbMapPinFilled" data-sentry-source-file="footer.index.tsx" />
                        <span className="t-dark4 t-p1">{job.location?.name}</span>
                    </div>
                </div>
            </div>
            <div className={`gap-12 ${styles['btns']}`}>
                {profile ? <>
                        <Button className="ph-2-i" icon={job.bookmark_id ? <TbStarFilled size={18} /> : <TbStar size={18} />} type="primary" {...bookmarkClick}>
                            {props.data.bookmark_id ? lang.bookmarked : lang.bookmark}
                        </Button>
                        <Button className="ph-2-i" {...requestClick}>
                            {lang.sendResume}
                        </Button>
                    </> : <>
                        {link(<Button className="ph-2-i" icon={<TbStar size={18} />} type="primary">
                                {props.data.bookmark_id ? lang.bookmarked : lang.bookmark}
                            </Button>)}
                        {link(<Button className="ph-2-i">{lang.sendResume}</Button>)}
                    </>}
            </div>
        </div>;
}