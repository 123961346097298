import { useContext } from 'react';
import styles from './footer.module.scss';
import { GlobalContext } from '@/context';
import Image from 'next/image';
import logoFA from 'assets/images/logo-typo-fa-white.svg';
import logoEN from 'assets/images/logo-typo-en-white.svg';
import { TbBrandFacebook, TbBrandInstagram, TbBrandLinkedin, TbBrandTelegram, TbBrandTiktok, TbBrandWhatsapp, TbBrandX, TbBrandYoutube } from 'react-icons/tb';
import { PATH } from 'data';
import { AppLinkComp } from '..';
import { usePathname } from 'next/navigation';
import { LanguageEnum, ServiceTypeEnum } from '@/types';
export default function FooterComp() {
  const {
    langText,
    profile,
    location
  } = useContext(GlobalContext);
  const lang = langText.header;
  const language = langText.lang;
  const pathname = usePathname();
  const isPage = (path: string) => pathname.split('/').join('').split('?')[0] === path.split('/').join('');
  const isLink = (item?: ServiceTypeEnum) => !(isPage(PATH(language).home()) || isPage(PATH(language).services.index(location.slug)) && item === ServiceTypeEnum.offer || isPage(PATH(language).dating) && item === ServiceTypeEnum.dating && profile);
  const links = [{
    title: lang.home,
    href: PATH(language).home(),
    searchParamsSensitive: false
  }, {
    title: lang.services,
    href: PATH(language).services.index(location.slug)
  }, {
    title: lang.housing,
    id: ServiceTypeEnum.housing
  }, {
    title: lang.hiring,
    id: ServiceTypeEnum.hiring
  }, {
    title: lang.events,
    id: ServiceTypeEnum.events
  },
  // {
  //     title: lang.dating,
  //     href: profile ? PATH(language).dating : undefined,
  //     id: profile ? undefined : ServiceTypeEnum.dating,
  // },
  {
    title: lang.aboutUs,
    href: PATH(language).aboutUs
  }
  // { title: lang., href: PATH(language).aboutUs },
  ];
  return <footer className={`${styles['tehranto-footer']} flex-column`} data-sentry-component="FooterComp" data-sentry-source-file="footer.index.tsx">
            <div className="flex-1 w-100 main-content gap-16 justify-content-between align-items-center flex-pre-md-column pv-6">
                <AppLinkComp href={PATH(langText.lang).home()} data-sentry-element="AppLinkComp" data-sentry-source-file="footer.index.tsx">
                    <h1 className="t-h3 t-white align-items-center gap-8">
                        <Image src={langText.lang === LanguageEnum.en ? logoEN : logoFA} alt="Tehranto" height={40} data-sentry-element="Image" data-sentry-source-file="footer.index.tsx" />
                    </h1>
                </AppLinkComp>
                <div className={`center-content flex-wrap ${styles['links']}`}>
                    {links.map((link, index) => <AppLinkComp key={index} href={link.href ?? PATH(language).home(link.id)} searchParamsSensitive={link.id ? false : link.searchParamsSensitive} preventReload={link.href ? isPage(link.href) : !isLink(link.id)} className={`t-white ${link.href && isPage(link.href) ? 't-600' : ''}`}>
                            {link.title}
                        </AppLinkComp>)}
                </div>
            </div>
            <div className="gap-24 main-content w-100 justify-content-center align-items-center mb-2 flex-wrap">
                <a aria-label="Whatsapp" className="t-white" target="_blank" href="https://wa.me/14169009821">
                    <TbBrandWhatsapp size={28} data-sentry-element="TbBrandWhatsapp" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="Instagram" className="t-white" target="_blank" href="https://www.instagram.com/tehranto.club">
                    <TbBrandInstagram size={28} data-sentry-element="TbBrandInstagram" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="TikTok" className="t-white" target="_blank" href="https://www.tiktok.com/@tehranto.club">
                    <TbBrandTiktok size={28} data-sentry-element="TbBrandTiktok" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="Facebook" className="t-white" target="_blank" href="https://www.facebook.com/tehranto.club">
                    <TbBrandFacebook size={28} data-sentry-element="TbBrandFacebook" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="Youtube" className="t-white" target="_blank" href="https://www.youtube.com/@tehrantoclub">
                    <TbBrandYoutube size={28} data-sentry-element="TbBrandYoutube" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="Telegram" className="t-white" target="_blank" href="https://t.me/tehranto_club">
                    <TbBrandTelegram size={28} data-sentry-element="TbBrandTelegram" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="Linkedin" className="t-white" target="_blank" href="https://www.linkedin.com/company/tehrantoclub">
                    <TbBrandLinkedin size={28} data-sentry-element="TbBrandLinkedin" data-sentry-source-file="footer.index.tsx" />
                </a>
                <a aria-label="X" className="t-white" target="_blank" href="https://twitter.com/tehrantoclub">
                    <TbBrandX size={28} data-sentry-element="TbBrandX" data-sentry-source-file="footer.index.tsx" />
                </a>
            </div>
            <div className={`center-content ${styles['rights']}`}>
                <p className="t-white">© 2024 Tehranto. All rights reserved.</p>
            </div>
        </footer>;
}