'use client';

import { GlobalContext } from '@/context';
import { AccommodationTypeData, BuildingTypeData, PATH, RentTypeData } from '@/data';
import { TenantType, RentEnum } from '@/types';
import { useContext } from 'react';
import styles from './roommatesCard.module.scss';
import Link from 'next/link';
import { ExtractTitle, HousingPriceConvert } from '@/scripts';
function RoommatesCardComp({
  item,
  className
}: {
  item: TenantType;
  className?: string;
}) {
  const language = useContext(GlobalContext).langText;
  const lang = language.roommatesList;
  const langText = language.lang;
  const age = item.birth_year ? new Date().getFullYear() - item.birth_year : undefined;
  const price = HousingPriceConvert(item.budget, item.price_mode);
  return <Link href={PATH(langText).roommates.detail(item)} className={className ?? ''} target="_blank" data-sentry-element="Link" data-sentry-component="RoommatesCardComp" data-sentry-source-file="roommatesCard.index.tsx">
            <div className={`${styles['card-layout']}`}>
                <div className="t-700 t-p1 t-dark1 d-flex align-items-center justify-content-between">
                    {item.user?.first_name}
                    <p className="t-700 t-p1 t-dark1">{age ? age + ' ' + lang.year : null}</p>
                </div>
                <p className="t-400 t-p1 t-primary pb-2">{item.location?.name}</p>
                <p className="t-400 t-p1 t-dark2 mb-1">
                    {lang.stayType}: {ExtractTitle(AccommodationTypeData, item.accommodation_type)}
                </p>
                <p className="t-400 t-p1 t-dark2">
                    {lang.buildingType}: {ExtractTitle(BuildingTypeData(language.housesList), item.building_type)}
                </p>
                <span className="d-flex align-items-center justify-content-between">
                    <p className={`t-p1 t-400 t-${item.rent_type === RentEnum.Communal ? 'red' : 'green'}`}>
                        {ExtractTitle(RentTypeData(language.housesList), item.rent_type)}
                    </p>
                    <p className="t-dark4 t-p1 t-400">
                        {price.modeText(language.housesList)}/{' '}
                        <span className="t-p1 t-700 t-dark1">{price.show} CAD</span>
                    </p>
                </span>
            </div>
        </Link>;
}
export default RoommatesCardComp;