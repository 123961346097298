import { __HiringLang } from '@/tehranto/hiringList/hiringList.lang';
import { __HiringTabEnum } from './jpbCard.type';

export const __HiringTabsData = (lang: typeof __HiringLang) => [
    { title: lang.features, slug: __HiringTabEnum.features },
    { title: lang.requirements, slug: __HiringTabEnum.requirements },
    { title: lang.description, slug: __HiringTabEnum.description },
    { title: lang.benefits, slug: __HiringTabEnum.benefits },
    { title: lang.about, slug: __HiringTabEnum.about },
];
